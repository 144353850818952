@import '../../styles/variables';

.header{
  width: 100%;
  position: relative;
  overflow: hidden;

  @include from-tablet-portrait-up{
    height: 100vh;
  }

  &__logo{
    position: absolute;
    bottom: 5px;
    left: 50%;
    display: flex;
    align-items: center;

    transform: translateX(-10%);
    animation: 1s 2000ms sideAni forwards;

    @include from-tablet-portrait-up{
      bottom: 10px;
    }

    &__blades{
      display: flex;
      gap: 5px;
      transform: rotate(45deg);

      animation: 1s 1s ease rotation forwards;

      @include from-tablet-portrait-up{
        gap: 20px;
      }
    }
    &__blade_l{
      height: 60px;
      margin-bottom: 0;

      animation: 1s 1000ms ease animateBladeL forwards;

      @include from-tablet-portrait-up{
        height: 218px;
        animation: 1s 1000ms ease animateBladeLDesktop forwards;
      }
    }
    &__blade_r{
      height: 60px;
      margin-top: -10px;

      animation: 1s 1000ms ease animateBladeR forwards;

      @include from-tablet-portrait-up{
        height: 218px;
        animation: 1s 1000ms ease animateBladeRDesktop forwards;
      }
    }
    &__font{
      height: 28px;
      opacity: 0;
      transform: translateX(-120px);

      animation: 1s 2000ms ease fontAnimation forwards;

      @include from-tablet-portrait-up{
        height: 103px;

        animation: 1s 2000ms ease fontAnimationDesktop forwards;
      }
    }
  }

  img{
    width: 100%;
    height: 100%;
  }
}

@keyframes sideAni {
  0%{
    transform: translateX(-10%);
  }
  100%{
    transform: translateX(-50%);
  }
}
@keyframes rotation {
  0%{
    transform: rotate(45deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
@keyframes animateBladeL {
  0%{
    margin-bottom: 0;
  }
  100%{
    margin-bottom: 10px;
  }
}
@keyframes animateBladeLDesktop {
  0%{
    margin-bottom: 0;
  }
  100%{
    margin-bottom: 36px;
  }
}
@keyframes animateBladeR {
  0%{
    margin-top: -10px;
  }
  100%{
    margin-top: 10px;
  }
}
@keyframes animateBladeRDesktop {
  0%{
    margin-top: -10px;
  }
  100%{
    margin-top: 36px;
  }
}
@keyframes fontAnimation {
  0%{
    opacity: 0;
    transform: translateX(-80px);
  }
  100%{
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fontAnimationDesktop {
  0%{
    opacity: 0;
    transform: translateX(-200px);
  }
  100%{
    opacity: 1;
    transform: translateX(0);
  }
}
