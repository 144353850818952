@import './fonts';
@import './variables';

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  @include OpenSans;
  box-sizing: border-box;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

html,
body,
#root {
  height: 100%;
}
body {
  background-color: $grey2;
  color: $white;
  text-align: center;
}

a{
  color: $white;
  text-decoration: none;
  font-weight: 300;
  letter-spacing: 1px;

  &:hover{
    color: $red;
  }
}
